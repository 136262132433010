import React from 'react'
import { L10nProvider } from '@logdoc/l10n';
import { InstructionsLogDoc } from '../instructions'
const Ins = () => {
    return (
        <L10nProvider>
            <InstructionsLogDoc />
        </L10nProvider>
    )
}

export default Ins;
